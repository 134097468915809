.details {
  color: #fff;
}

.nameWrapper {
  align-items: center;
  margin-bottom: 9px;
  display: flex;
}

.companyName {
  letter-spacing: -.5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 5px 0 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  overflow: hidden;
}

.locationModule {
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
}

.locationPin {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.location {
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.sellerStats {
  cursor: pointer;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 9px;
  display: flex;
}

.ratingFraction {
  white-space: nowrap;
  margin-top: 3px;
  margin-left: 9px;
  font-size: 14px;
  font-weight: 300;
  display: flex;
}

@media (width <= 1024px) {
  .details {
    margin-left: 0;
    padding-top: 18px;
  }

  .nameWrapper {
    flex-flow: column;
    align-items: center;
  }

  .companyName {
    order: 2;
  }

  .sellerStats {
    justify-content: center;
  }

  .locationModule {
    justify-content: center;
    margin: 16px 0;
  }
}

.goldDiamond {
  fill: #a48e45;
}

.platinumDiamond {
  fill: #727383;
}

@media (width <= 568px) {
  .locationModule {
    display: none;
  }
}
