@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -50% 0;
  }
}
/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.section {
  font-size: 16px;
  font-weight: 300;
  padding: 18px 0 54px;
  position: relative;
}

.content {
  font-size: 12px;
  font-weight: 300;
}
:local(.isPreview) .content::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  z-index: 10020;
}

.gridItem {
  margin-bottom: 36px;
}
@media (min-width: 1025px) {
  .gridItem {
    padding: 0 18px 18px;
  }
}

.footer {
  margin-top: 54px;
  text-align: center;
}