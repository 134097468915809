:local .wrapper {
  z-index: 10;
  position: relative;
}

:local .cover {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

:local .cover.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

:local .cover.sticky .top {
  display: none;
}

:local .cover.sticky .detailsWrapper {
  flex: 0 auto;
}

:local .cover.sticky .tabsWrapper {
  flex: 1;
}

:local .cover.sticky .socialWrapper {
  order: 3;
}

:local .top {
  z-index: 10;
  width: 94%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}

:local .bottom {
  z-index: 10;
  width: 94%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

:local .left {
  position: relative;
}

:local .leftContent {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

:local .right {
  flex: 1;
  position: relative;
}

.cover.noPicture :local .right {
  position: absolute;
  inset: 0;
}

:local .rightContent {
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

:local .pictureWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}

:local .detailsWrapper {
  flex: 1;
}

:local .socialWrapper {
  align-self: flex-end;
}

:local .tabsWrapper {
  flex: 0 0 100%;
  margin-top: 6px;
}

@media (width <= 1024px) {
  :local .bottom {
    display: block;
  }

  :local .left {
    margin: auto;
  }

  :local .cover.noPicture .left {
    display: none;
  }

  :local .cover.noPicture .right {
    position: relative;
  }

  :local .rightContent {
    display: block;
    position: relative;
  }
}
