:local .form {
  background-color: #fff;
  max-width: 600px;
  padding: 18px 36px 36px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}

:local .form p {
  margin: 0 0 9px;
}

:local .inputsRow {
  flex-direction: row;
  margin: 18px 0;
  display: flex;
}

@media (width <= 768px) {
  :local .inputsRow {
    margin: 22px 0 27px;
  }
}

:local .countryCode {
  flex: 0 0 90px;
  width: 90px;
  margin-right: 9px;
}

:local .countryCode .input {
  width: 54px;
  display: inline-block;
}

:local .leftDecorator {
  background: #f3f3f3;
  margin-right: 9px;
}

:local .plusSign {
  text-align: center;
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  border-left: 1px solid #bbb;
  width: 36px;
  height: 50px;
  font-size: 16px;
  font-weight: 300;
  line-height: 50px;
  display: inline-block;
}

:is(:local .phoneNumber, :local .name) {
  flex: 1;
}

:local .checkboxLabel {
  color: #222;
}

:local .submit {
  width: 100%;
  margin: 9px 0 27px;
}

:local .submit:focus {
  outline: none;
}

@media (width <= 768px) {
  :local .submit {
    margin: 9px 0 18px;
  }
}

:local .inlineSpinner {
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  line-height: 16px;
  display: inline-block;
  position: relative;
}

:local .footer {
  color: #888;
  font-size: 14px;
  font-weight: 300;
}

:local .error {
  text-align: center;
  color: #c00;
  padding-bottom: 18px;
}

:local .allowCallbackPromptContainer {
  color: #fff;
  margin-bottom: 18px;
}

:local .allowCallbackDetails {
  color: #888;
  margin-left: 38px;
}
