:local .form {
  background-color: #fff;
  max-width: 600px;
  padding: 18px 36px 36px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}
:local .form p {
  margin: 0 0 9px;
}
:local .inputsRow {
  display: flex;
  flex-direction: row;
  margin: 18px 0;
}
@media (max-width: 768px) {
  :local .inputsRow {
    margin: 22px 0 27px 0;
  }
}
:local .countryCode {
  width: 90px;
  flex: 0 0 90px;
  margin-right: 9px;
}
:local .countryCode .input {
  display: inline-block;
  width: 54px;
}
:local .leftDecorator {
  margin-right: 9px;
  background: #f3f3f3;
}
:local .plusSign {
  display: inline-block;
  width: 36px;
  text-align: center;
  color: #222;
  background-color: #fff;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 300;
  vertical-align: middle;
  border-bottom: 1px solid #bbb;
  border-left: 1px solid #bbb;
  border-top: 1px solid #bbb;
}
:local .phoneNumber,
:local .name {
  flex: 1;
}
:local .checkboxLabel {
  color: #222;
}
:local .submit {
  width: 100%;
  margin: 9px 0 27px;
}
:local .submit:focus {
  outline: none;
}
@media (max-width: 768px) {
  :local .submit {
    margin: 9px 0 18px;
  }
}
:local .inlineSpinner {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  line-height: 16px;
  vertical-align: middle;
}
:local .footer {
  font-size: 14px;
  font-weight: 300;
  color: #888;
}
:local .error {
  padding-bottom: 18px;
  text-align: center;
  color: #cc0000;
}
:local .allowCallbackPromptContainer {
  margin-bottom: 18px;
  color: #fff;
}
:local .allowCallbackDetails {
  margin-left: 38px;
  color: #888;
}