:local .svg {
  vertical-align: top;
}
:local .fullStar {
  fill: #f9902d;
}
:local .emptyStar {
  fill: #ddd;
}
:local .gradientLeft,
:local .gradientRight {
  stop-opacity: 1;
  stop-color: #ddd;
}
:local .gradientLeft {
  stop-color: #f9902d;
}
:local .large {
  height: 36px;
  width: 36px;
}
:local .medium {
  height: 26px;
  width: 26px;
}
:local .small {
  height: 18px;
  width: 18px;
}
:local .smaller {
  height: 14px;
  width: 14px;
}
:local .xSmall {
  height: 12px;
  width: 12px;
  vertical-align: baseline;
}
:local .bordered {
  stroke: #f9902d;
  stroke-width: 10px;
}