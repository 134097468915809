:local .prompt {
  color: #666;
  background-color: #fff;
  max-width: 600px;
  padding: 36px;
  font-size: 16px;
  font-weight: 300;
}

:local .button {
  margin-top: 18px;
}
