.links {
  width: 60%;
  margin: 0 auto 45px;
}

.links .link {
  text-align: center;
  padding: 0 0 18px;
  font-size: 14px;
  font-weight: 300;
}

.wrapper {
  border-top: 9px solid #f3f3f3;
  width: 100vw;
  padding: 18px 18px 27px;
  position: relative;
  left: calc(-50vw + 50%);
}

.header {
  letter-spacing: -.5px;
  margin: 0 0 18px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.innerWrapper {
  -webkit-overflow-scrolling: touch;
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  overflow: scroll hidden;
}

.innerWrapper::-webkit-scrollbar {
  display: none;
}

.item {
  color: #222;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid #bbb;
  align-items: center;
  height: 40px;
  margin-right: 9px;
  padding: 0 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  transition: color .2s;
  display: flex;
}

.item:hover {
  color: #444;
}

@media (hover: none) {
  .item:hover {
    color: #000;
  }
}

.item:last-child {
  margin-right: 0;
}

.item .noUnderline {
  text-decoration: none;
}

.itemContainer {
  width: fit-content;
  padding: 0 18px 18px;
  display: flex;
}
