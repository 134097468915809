.distinction {
  margin-left: 15px;
  display: flex;
  position: relative;
}

.copy {
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  opacity: .7;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
}

.icon {
  width: 17px;
  height: 17px;
  margin-right: 9px;
}

.tooltipText {
  color: #666;
}

@media (width <= 1024px) {
  .distinction {
    order: 1;
    margin-bottom: 9px;
    font-size: 12px;
    font-weight: 300;
  }
}
