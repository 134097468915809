.wrapper {
  white-space: nowrap;
}

.inlineWrapper {
  display: inline-block;
}

.star {
  display: inline-block;
}

.small + .small {
  margin-left: 9px;
}

.medium + .medium {
  margin-left: 18px;
}

.large + .large {
  margin-left: 27px;
}

.xSmall + .xSmall {
  margin-left: 3px;
}

.selectable {
  cursor: pointer;
}