/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.section:not(.minimal) {
  font-size: 16px;
  font-weight: 300;
  padding: 18px 0 54px;
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
  background: #f6f3eb;
}

.content {
  max-width: 1248px;
  margin: auto;
}
.minimal .content {
  font-size: 16px;
  font-weight: 300;
  padding: 18px 0 54px;
  border-bottom: 1px solid #ddd;
  padding-top: 54px;
}

.about {
  height: 100%;
  overflow: hidden;
  position: relative;
  margin-right: 54px;
  transition: 250ms height ease;
}

.aboutUs {
  white-space: pre-line;
  transition: 250ms height ease;
  line-height: 2;
}

.divider {
  line-height: 20px;
  border-left: 1px solid #ddd;
  margin: 0 9px;
}

.readMoreLess {
  cursor: pointer;
  margin-left: 9px;
  color: #000;
}
.readMoreLess:hover {
  color: #444;
}

.minimal .info {
  text-align: center;
}

.logo {
  display: block;
  max-width: 100%;
  margin-bottom: 18px;
  border: 10px solid #fff;
}
:local(.minimal) .logo {
  border: 0;
  margin: 18px auto;
}

.yearJoined {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: italic;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1;
  font-size: 14px;
  margin-bottom: 18px;
  color: #666;
}

.vatInfoLabel,
.associations {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
  color: #666;
}

.vatInfo,
.association {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 18px;
  color: #666;
}

.contact {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  display: block;
  line-height: 1;
  margin-bottom: 18px;
}
.contact:focus {
  outline: none;
}

@media (max-width: 1024px) {
  .about {
    padding: 0 18px;
    margin-right: 0;
  }
}
@media (max-width: 568px) {
  .about {
    padding: 0 18px;
    margin-right: 0;
  }
  .info {
    text-align: center;
  }
  .logo {
    margin: 18px auto;
  }
}