:local .prompt {
  background-color: #fff;
  max-width: 600px;
  padding: 36px;
  font-size: 16px;
  font-weight: 300;
  color: #666;
}
:local .button {
  margin-top: 18px;
}