.headshot {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #c2a661;
}

.image {
  width: 100%;
  height: auto;
}