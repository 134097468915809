@keyframes :global(ring) {
  0% {
    transform: rotate(2deg);
  }
  10% {
    transform: rotate(-2deg);
  }
  40% {
    transform: rotate(2deg);
  }
  45% {
    transform: rotate(-4deg);
  }
  65% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(2deg);
  }
}
@keyframes :global(shine) {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  75% {
    transform: scale(1);
  }
  90% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
:global .phone {
  animation: ring 2s ease-in-out;
  animation-iteration-count: infinite;
  transform-origin: center 65%;
}
:global .ray1,
:global .ray2,
:global .ray3,
:global .ray4,
:global .ray5 {
  animation: shine 2s ease-in-out;
  animation-iteration-count: infinite;
}
:global .ray1 {
  transform-origin: 30% 14%;
}
:global .ray2 {
  transform-origin: 50% 11%;
}
:global .ray3 {
  transform-origin: 68% 15%;
}
:global .ray4 {
  transform-origin: 85% 26%;
}
:global .ray5 {
  transform-origin: 17% 26%;
}

:local .wrapper {
  background-color: #f6f3eb;
}
:local .steps {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  padding: 0 9px 27px;
}
:local .step {
  margin: 0 9px;
  max-width: 216px;
}
:local .stepNumber {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin-bottom: 9px;
}
:local .stepDescription {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  color: #222;
  line-height: 1.15;
}
:local .icon {
  max-width: 54px;
}
:local .nextStepIcon {
  max-height: 54px;
  max-width: 27px;
  fill: #888;
}
:local .confirmationHeader {
  text-align: center;
  padding-bottom: 36px;
}
:local .iconRinging {
  width: 54px;
  margin-top: 18px;
}
:local .headerWrapper {
  padding: 27px 36px 18px;
  color: #222;
  text-align: center;
}
:local .modalBackButton {
  display: none;
}
@media (max-width: 768px) {
  :local .modalBackButton {
    display: block;
  }
}