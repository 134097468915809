.section:not(.minimal) {
  background: #f6f3eb;
  width: 100vw;
  padding: 18px 0 54px;
  font-size: 16px;
  font-weight: 300;
  position: relative;
  left: calc(-50vw + 50%);
}

.content {
  max-width: 1248px;
  margin: auto;
}

.minimal .content {
  border-bottom: 1px solid #ddd;
  padding: 54px 0;
  font-size: 16px;
  font-weight: 300;
}

.about {
  height: 100%;
  margin-right: 54px;
  transition: height .25s;
  position: relative;
  overflow: hidden;
}

.aboutUs {
  white-space: pre-line;
  line-height: 2;
  transition: height .25s;
}

.divider {
  border-left: 1px solid #ddd;
  margin: 0 9px;
  line-height: 20px;
}

.readMoreLess {
  cursor: pointer;
  color: #000;
  margin-left: 9px;
}

.readMoreLess:hover {
  color: #444;
}

.minimal .info {
  text-align: center;
}

.logo {
  border: 10px solid #fff;
  max-width: 100%;
  margin-bottom: 18px;
  display: block;
}

:local(.minimal) .logo {
  border: 0;
  margin: 18px auto;
}

.yearJoined {
  letter-spacing: 0;
  color: #666;
  margin-bottom: 18px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 14px;
  font-style: italic;
  font-weight: normal;
  line-height: 1;
}

.vatInfoLabel, .associations {
  color: #666;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
}

.vatInfo, .association {
  color: #666;
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: 300;
}

.contact {
  margin-bottom: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  display: block;
}

.contact:focus {
  outline: none;
}

@media (width <= 1024px) {
  .about {
    margin-right: 0;
    padding: 0 18px;
  }
}

@media (width <= 568px) {
  .about {
    margin-right: 0;
    padding: 0 18px;
  }

  .info {
    text-align: center;
  }

  .logo {
    margin: 18px auto;
  }
}
