:local .svg {
  vertical-align: top;
}

:local .fullStar {
  fill: #f9902d;
}

:local .emptyStar {
  fill: #ddd;
}

:is(:local .gradientLeft, :local .gradientRight) {
  stop-opacity: 1;
  stop-color: #ddd;
}

:local .gradientLeft {
  stop-color: #f9902d;
}

:local .large {
  width: 36px;
  height: 36px;
}

:local .medium {
  width: 26px;
  height: 26px;
}

:local .small {
  width: 18px;
  height: 18px;
}

:local .smaller {
  width: 14px;
  height: 14px;
}

:local .xSmall {
  vertical-align: baseline;
  width: 12px;
  height: 12px;
}

:local .bordered {
  stroke: #f9902d;
  stroke-width: 10px;
}
