.backButton {
  cursor: pointer;
  position: absolute;
  width: 30px;
  height: 30px;
  padding: 5px;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
}
.backButton.translucentWhite {
  background-color: rgba(255, 255, 255, 0.7);
}
.backButton svg {
  fill: #888;
}
.backButton:hover svg {
  fill: #222;
}

.backButtonDisabled {
  cursor: not-allowed;
}
.backButtonDisabled:hover svg {
  fill: #888;
}