/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.links {
  width: 60%;
  margin: 0 auto 45px;
}
.links .link {
  font-size: 14px;
  font-weight: 300;
  padding: 0 0 18px;
  text-align: center;
}

.wrapper {
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
  padding: 18px 18px 27px;
  border-top: 9px solid #f3f3f3;
}

.header {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin: 0 0 18px;
}

.innerWrapper {
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.innerWrapper::-webkit-scrollbar {
  display: none;
}

.item {
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: none;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid #bbb;
  padding: 0 18px;
  margin-right: 9px;
  white-space: nowrap;
  color: #222;
}
.item:hover {
  color: #444;
}
@media (hover: none) {
  .item:hover {
    color: #000;
  }
}
.item:last-child {
  margin-right: 0;
}
.item .noUnderline {
  text-decoration: none;
}

.itemContainer {
  display: flex;
  padding: 0 18px 18px;
  width: fit-content;
}