@keyframes folder-slide {
  0%, 100% {
    top: 0;
    left: 0;
  }
  50% {
    top: 0;
    left: -4px;
  }
}
@keyframes folder-open {
  0%, 100% {
    opacity: 0;
  }
  40%, 60% {
    opacity: 1;
    transform: skew(-15deg);
  }
}
:local .icon {
  z-index: 10;
  stroke-width: 12px;
  stroke: #c2a661;
  fill: transparent;
  transition: fill 0.2s ease;
}
:local .icon.isIcon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
:local .icon.isButton {
  height: 26px;
  width: 26px;
}
:local .icon.isAnimated, :local .icon.isFilled, :local .icon:hover {
  fill: #c2a661;
}
:local .icon.isAnimated {
  animation: folder-slide 0.7s ease-in-out;
}
:local .icon.isStrokeWidthHeavy {
  stroke-width: 18px;
}
:local .icon.dark {
  stroke: #222;
}
:local .icon.dark.isAnimated, :local .icon.dark.isFilled, :local .icon.dark:hover {
  fill: #222;
}
:local .icon.light {
  stroke: #fff;
}
:local .icon.light.isAnimated, :local .icon.light.isFilled, :local .icon.light:hover {
  fill: #fff;
}
:local .iconButton {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  align-items: center;
  background: #fff;
  border: 2px solid #fff;
  color: #222;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 40px;
  outline: none;
  padding: 7px;
  text-align: center;
  transition: all 175ms linear;
  vertical-align: top;
  width: auto;
}
:local .iconButton:hover, :local .iconButton:hover > .icon {
  color: #c2a661;
  stroke: #c2a661;
}
:local .iconButton.disabled {
  opacity: 0.2;
  pointer-events: none;
}
:local .iconButton > .icon {
  transition: all 175ms linear;
  fill: #fff;
  stroke: #222;
  height: 100%;
}
:local .text {
  line-height: 1;
  margin: auto;
  padding-left: 9px;
}
:local .iconShadow {
  position: absolute;
  top: 4px;
  left: 4px;
  height: 70%;
  width: 80%;
  border-radius: 12%;
  background: #a48e45;
  visibility: hidden;
}
:local .iconShadow.dark {
  background: #222;
}
:local .iconShadow.light {
  background: #fff;
}
:local .iconShadow.isAnimated {
  visibility: visible;
  animation: folder-open 0.7s ease-in-out;
}
:local .iconWrapper {
  background: none;
  border: none;
  width: 100%;
  height: 100%;
}
:local .iconWrapper.button {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 9px;
  width: 44px;
  height: 44px;
  transition: 0.5s transform ease;
  border-radius: 50%;
}
:local .iconWrapper.button:active, :local .iconWrapper.button.isActive {
  transform: scale(0.93);
}
:local .iconWrapper.button:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px 0px rgba(34, 34, 34, 0.15);
  transition: 0.5s opacity ease;
  border-radius: 50%;
}
:local .iconWrapper.button:active:after, :local .iconWrapper.button.isActive:after {
  opacity: 0;
}
:local .folder {
  z-index: 10;
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
:local .folder:hover {
  cursor: pointer;
}
:local .folder.button {
  right: 50%;
  top: 50%;
  transform: translate3d(50%, -50%, 0);
  height: 23px;
  width: 23px;
}
:local svg.icon:not(:root) {
  overflow: visible;
  padding: 1px;
}