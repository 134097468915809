.headshot {
  background: #fff;
  border: 1px solid #c2a661;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.image {
  width: 100%;
  height: auto;
}
