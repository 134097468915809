.container {
  width: 40px;
  height: 40px;
  position: relative;
}

.wrapper {
  transition: left .175s;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

:local(.isOpen) .wrapper {
  left: -120px;
}

.socialWrapper {
  background: #fff;
  width: 120px;
  height: 80px;
  margin: 0;
  transition: all .175s;
  position: absolute;
  top: 0;
  right: -120px;
}

:local(.isOpen) .socialWrapper {
  right: 40px;
}

:local(.showThanks) .socialWrapper {
  top: -40px;
}

.button {
  width: 40px;
  height: 40px;
}

.share {
  float: right;
  color: #fff;
  padding: 9px;
  transition: background .175s linear;
  position: relative;
}

.share:hover {
  color: #222;
  background: #fff;
}

:local(.isOpen) .share {
  color: #fff;
  background: #222;
}

.social {
  color: #222;
  padding: 12px;
  transition: all 75ms linear;
}

.social:hover {
  color: #222;
  transform: translateY(-5px);
}

.social:active {
  transform: translateY(5px);
}

.socialSvg {
  transition: all .4s .175s;
  transform: translateX(120px)rotate(-150deg);
}

:local(.isOpen) .socialSvg {
  transform: translateX(0)rotate(-360deg);
}

.thankYouMessage {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
}
