:global .phone {
  transform-origin: 50% 65%;
  animation: 2s ease-in-out infinite ring;
}

:is(:global .ray1, :global .ray2, :global .ray3, :global .ray4, :global .ray5) {
  animation: 2s ease-in-out infinite shine;
}

:global .ray1 {
  transform-origin: 30% 14%;
}

:global .ray2 {
  transform-origin: 50% 11%;
}

:global .ray3 {
  transform-origin: 68% 15%;
}

:global .ray4 {
  transform-origin: 85% 26%;
}

:global .ray5 {
  transform-origin: 17% 26%;
}

:local .wrapper {
  background-color: #f6f3eb;
}

:local .steps {
  text-align: center;
  justify-content: space-evenly;
  padding: 0 9px 27px;
  display: flex;
}

:local .step {
  max-width: 216px;
  margin: 0 9px;
}

:local .stepNumber {
  letter-spacing: -.5px;
  margin-bottom: 9px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

:local .stepDescription {
  color: #222;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  line-height: 1.15;
}

:local .icon {
  max-width: 54px;
}

:local .nextStepIcon {
  fill: #888;
  max-width: 27px;
  max-height: 54px;
}

:local .confirmationHeader {
  text-align: center;
  padding-bottom: 36px;
}

:local .iconRinging {
  width: 54px;
  margin-top: 18px;
}

:local .headerWrapper {
  color: #222;
  text-align: center;
  padding: 27px 36px 18px;
}

:local .modalBackButton {
  display: none;
}

@media (width <= 768px) {
  :local .modalBackButton {
    display: block;
  }
}
