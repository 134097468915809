.backButton {
  cursor: pointer;
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  padding: 5px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.backButton.translucentWhite {
  background-color: #ffffffb3;
}

.backButton svg {
  fill: #888;
}

.backButton:hover svg {
  fill: #222;
}

.backButtonDisabled {
  cursor: not-allowed;
}

.backButtonDisabled:hover svg {
  fill: #888;
}
