:local .wrapper {
  position: relative;
  z-index: 10;
}
:local .cover {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}
:local .cover.sticky {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
}
:local .cover.sticky .top {
  display: none;
}
:local .cover.sticky .detailsWrapper {
  flex: 0 1 auto;
}
:local .cover.sticky .tabsWrapper {
  flex: 1;
}
:local .cover.sticky .socialWrapper {
  order: 3;
}
:local .top {
  width: 94%;
  margin: 0 auto;
  max-width: 1440px;
  position: relative;
  z-index: 10;
}
:local .bottom {
  display: flex;
  width: 94%;
  margin: 0 auto;
  max-width: 1440px;
  position: relative;
  z-index: 10;
}
:local .left {
  position: relative;
}
:local .leftContent {
  width: 100%;
  position: relative;
  padding-bottom: 100%;
}
:local .right {
  flex: 1;
  position: relative;
}
.cover.noPicture :local .right {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
:local .rightContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  align-items: flex-end;
}
:local .pictureWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}
:local .detailsWrapper {
  flex: 1;
}
:local .socialWrapper {
  align-self: flex-end;
}
:local .tabsWrapper {
  margin-top: 6px;
  flex: 0 0 100%;
}
@media (max-width: 1024px) {
  :local .bottom {
    display: block;
  }
  :local .left {
    margin: auto;
  }
  :local .cover.noPicture .left {
    display: none;
  }
  :local .cover.noPicture .right {
    position: relative;
  }
  :local .rightContent {
    display: block;
    position: relative;
  }
}