@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -50% 0;
  }
}

.section {
  padding: 18px 0 54px;
  font-size: 16px;
  font-weight: 300;
  position: relative;
}

.content {
  font-size: 12px;
  font-weight: 300;
}

:local(.isPreview) .content:after {
  content: "";
  z-index: 10020;
  position: absolute;
  inset: 0;
}

.gridItem {
  margin-bottom: 36px;
}

@media (width >= 1025px) {
  .gridItem {
    padding: 0 18px 18px;
  }
}

.footer {
  text-align: center;
  margin-top: 54px;
}
