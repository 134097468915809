.details {
  color: #fff;
}

.nameWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}

.companyName {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin: 5px 0 0;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.locationModule {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.locationPin {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.location {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  white-space: nowrap;
}

.sellerStats {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 9px;
  cursor: pointer;
}

.ratingFraction {
  font-size: 14px;
  font-weight: 300;
  display: flex;
  margin-left: 9px;
  margin-top: 3px;
  white-space: nowrap;
}

@media (max-width: 1024px) {
  .details {
    margin-left: 0;
    padding-top: 18px;
  }
  .nameWrapper {
    flex-flow: column;
    align-items: center;
  }
  .companyName {
    order: 2;
  }
  .sellerStats {
    justify-content: center;
  }
  .locationModule {
    margin: 16px 0;
    justify-content: center;
  }
}
.goldDiamond {
  fill: #a48e45;
}

.platinumDiamond {
  fill: #727383;
}

@media (max-width: 568px) {
  .locationModule {
    display: none;
  }
}