.container {
  position: relative;
  width: 40px;
  height: 40px;
}

.wrapper {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: left 175ms ease;
}
:local(.isOpen) .wrapper {
  left: -120px;
}

.socialWrapper {
  margin: 0;
  position: absolute;
  width: 120px;
  height: 80px;
  background: #fff;
  top: 0;
  right: -120px;
  transition: all 175ms ease;
}
:local(.isOpen) .socialWrapper {
  right: 40px;
}
:local(.showThanks) .socialWrapper {
  top: -40px;
}

.button {
  width: 40px;
  height: 40px;
}

.share {
  position: relative;
  float: right;
  padding: 9px;
  color: #fff;
  transition: background 175ms linear;
}
.share:hover {
  color: #222;
  background: #fff;
}
:local(.isOpen) .share {
  color: #fff;
  background: #222;
}

.social {
  padding: 12px;
  color: #222;
  transition: 75ms all linear;
}
.social:hover {
  transform: translateY(-5px);
  color: #222;
}
.social:active {
  transform: translateY(5px);
}

.socialSvg {
  transition: 400ms all 175ms ease;
  transform: translateX(120px) rotate(-150deg);
}
:local(.isOpen) .socialSvg {
  transform: translateX(0px) rotate(-360deg);
}

.thankYouMessage {
  font-size: 18px;
  font-weight: 300;
  color: #000;
  text-align: center;
}