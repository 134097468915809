.favoritesWrapper {
  z-index: 10;
  padding: 9px;
  position: absolute;
  top: 0;
  right: 0;
}

.icon {
  width: 26px;
  height: 26px;
  margin-top: 9px;
  transform: scale(.8);
}

.icon.enlargedIcon {
  transform: scale(1);
}

.icon.favoritesSpacing {
  margin-top: 0;
}
