.social {
  display: flex;
  flex-wrap: nowrap;
}

.socialButton {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  cursor: pointer;
  width: 130px;
  margin-left: 10px;
  padding: 12px 30px;
  color: #fff;
  border: 1px solid #fff;
  line-height: 1;
  text-align: center;
  transition: 125ms background ease;
}
.socialButton:hover {
  background-color: #fff;
  color: #222;
}

.contact {
  background: #fff;
  color: #222;
  border-color: #fff;
}
.contact:hover {
  background: #222;
  color: #fff;
  border-color: #fff;
}

.shareButton {
  cursor: pointer;
  border: 1px solid #fff;
}

.socialShareWrapper {
  margin: -1px;
}

@media (max-width: 1024px) {
  .social {
    justify-content: center;
  }
}
@media (max-width: 568px) {
  .shareButton {
    display: none;
  }
}