.cover:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
  background: url(https://a.1stdibscdn.com/assets/images/profile/darken_overlay_tile.png) repeat;
}
.cover.noPicture .details,
.cover.noPicture .tabsWrapper {
  margin-left: 0;
}

.top {
  padding-bottom: 95px;
}

.details {
  margin-left: 27px;
}

.headshot {
  transform: translateY(-20px);
}

.tabsWrapper {
  margin-left: 27px;
  position: relative;
  border-top: 1px solid #c2a661;
  height: 48px;
}

.follow {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  text-decoration: none;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  padding: 13px 0;
  cursor: pointer;
}
.follow a:hover, .follow:hover {
  color: #fff;
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .profileCover {
    padding-top: 36px;
  }
  .top {
    padding-bottom: 18px;
  }
  .headshot {
    transform: none;
  }
  .details {
    margin-left: 0;
  }
  .tabsWrapper {
    margin-left: 0;
  }
}