.cover:after {
  content: "";
  opacity: .8;
  background: url("https://a.1stdibscdn.com/assets/images/profile/darken_overlay_tile.png");
  position: absolute;
  inset: 0;
}

.cover.noPicture .details, .cover.noPicture .tabsWrapper {
  margin-left: 0;
}

.top {
  padding-bottom: 95px;
}

.details {
  margin-left: 27px;
}

.headshot {
  transform: translateY(-20px);
}

.tabsWrapper {
  border-top: 1px solid #c2a661;
  height: 48px;
  margin-left: 27px;
  position: relative;
}

.follow {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  cursor: pointer;
  padding: 13px 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.follow a:hover, .follow:hover {
  color: #fff;
  text-decoration: underline;
}

@media (width <= 1024px) {
  .profileCover {
    padding-top: 36px;
  }

  .top {
    padding-bottom: 18px;
  }

  .headshot {
    transform: none;
  }

  .details, .tabsWrapper {
    margin-left: 0;
  }
}
