.flag {
  width: 21px;
  height: 14px;
}

.arrow {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.flagSelector {
  width: 50px;
}

.selectWrapper {
  position: absolute;
  opacity: 0;
  width: 33px;
  left: 0;
  top: 0;
}

.phoneNumberWrapper {
  display: flex;
  position: relative;
}

.flagWrapper {
  display: flex;
  align-items: center;
  position: absolute;
  padding-left: 18px;
  z-index: 10;
}

.flagWrapperTop {
  top: 0;
}

.flagWrapperBottom {
  bottom: 0;
}

.flagWrapperTopWithLabel {
  top: 30px;
}

.flagWrapperHeightSmall {
  height: 30px;
}

.flagWrapperHeightMedium {
  height: 40px;
}

.flagWrapperHeightHigh {
  height: 50px;
}

.flagIconWrapper {
  display: flex;
}

.emptySpacing {
  width: 60px;
}

.inputClass {
  padding-left: 9px;
}

.spinner {
  padding-left: 9px;
  width: 27px;
  height: 27px;
}