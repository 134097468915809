.mediaCarousel {
  align-items: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.prev, .next {
  cursor: pointer;
  color: #444;
  flex: 0 0 15%;
  align-items: center;
  transition: color .25s;
  display: flex;
  position: relative;
}

.prev:hover, .next:hover {
  color: #000;
}

:is(:local(.mediaCarousel.disable) .prev, :local(.mediaCarousel.disable) .next) {
  visibility: hidden;
}

.prev {
  order: 0;
  justify-content: flex-end;
  margin-right: 30px;
}

.next {
  order: 2;
  justify-content: flex-start;
  margin-left: 30px;
}

.arrow {
  width: 50px;
  height: 50px;
  margin: 30px;
}

.carousel {
  flex: 0 0 70%;
  order: 1;
  width: 70%;
  min-width: 0;
  min-height: 0;
  transition: transform .5s;
  position: relative;
  transform: translateX(0%);
}

:local(.mediaCarousel.swipable) .carousel {
  margin: auto;
}

.carouselItems {
  flex-wrap: nowrap;
  display: flex;
}

.carouselItem {
  flex: 0 0 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0 10px;
  transition: opacity .5s;
  transform: translate3d(0, 0, 0);
}

.mediaWrapper {
  width: 100%;
  margin-bottom: 15px;
  padding-top: 70%;
  position: relative;
}

.mediaContainer {
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.photo {
  border: 1px solid #bbb;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}

.video {
  width: 100%;
  height: 100%;
}

.caption {
  text-align: center;
}

@media (width <= 1024px) {
  .prev, .next {
    margin: 0;
  }
}

@media (width <= 768px) {
  .arrow {
    margin: 10px;
  }
}
