.section {
  background: #f6f3eb;
  width: 100vw;
  padding: 18px 0 54px;
  font-size: 16px;
  font-weight: 300;
  position: relative;
  left: calc(-50vw + 50%);
  overflow: hidden;
}

.content {
  width: calc(100% - 72px);
  width: calc(100% - 2 * var(--sassy-layout-offset));
  max-width: 1440px;
  margin: 0 auto;
}
