.distinction {
  display: flex;
  position: relative;
  margin-left: 15px;
}

.copy {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  white-space: nowrap;
  opacity: 0.7;
}

.icon {
  width: 17px;
  height: 17px;
  margin-right: 9px;
}

.tooltipText {
  color: #666;
}

@media (max-width: 1024px) {
  .distinction {
    font-size: 12px;
    font-weight: 300;
    order: 1;
    margin-bottom: 9px;
  }
}