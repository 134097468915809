@keyframes folder-slide {
  0%, 100% {
    top: 0;
    left: 0;
  }

  50% {
    top: 0;
    left: -4px;
  }
}

@keyframes folder-open {
  0%, 100% {
    opacity: 0;
  }

  40%, 60% {
    opacity: 1;
    transform: skew(-15deg);
  }
}

:local .icon {
  z-index: 10;
  stroke-width: 12px;
  stroke: #c2a661;
  fill: #0000;
  transition: fill .2s;
}

:local .icon.isIcon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

:local .icon.isButton {
  width: 26px;
  height: 26px;
}

:is(:local .icon.isAnimated, :local .icon.isFilled, :local .icon:hover) {
  fill: #c2a661;
}

:local .icon.isAnimated {
  animation: .7s ease-in-out folder-slide;
}

:local .icon.isStrokeWidthHeavy {
  stroke-width: 18px;
}

:local .icon.dark {
  stroke: #222;
}

:is(:local .icon.dark.isAnimated, :local .icon.dark.isFilled, :local .icon.dark:hover) {
  fill: #222;
}

:local .icon.light {
  stroke: #fff;
}

:is(:local .icon.light.isAnimated, :local .icon.light.isFilled, :local .icon.light:hover) {
  fill: #fff;
}

:local .iconButton {
  color: #222;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  vertical-align: top;
  background: #fff;
  border: 2px solid #fff;
  outline: none;
  align-items: center;
  width: auto;
  height: 40px;
  padding: 7px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  transition: all .175s linear;
  display: flex;
}

:local .iconButton:hover {
  color: #c2a661;
  stroke: #c2a661;
}

:local .iconButton:hover > .icon {
  color: #c2a661;
  stroke: #c2a661;
}

:local .iconButton.disabled {
  opacity: .2;
  pointer-events: none;
}

:local .iconButton > .icon {
  fill: #fff;
  stroke: #222;
  height: 100%;
  transition: all .175s linear;
}

:local .text {
  margin: auto;
  padding-left: 9px;
  line-height: 1;
}

:local .iconShadow {
  visibility: hidden;
  background: #a48e45;
  border-radius: 12%;
  width: 80%;
  height: 70%;
  position: absolute;
  top: 4px;
  left: 4px;
}

:local .iconShadow.dark {
  background: #222;
}

:local .iconShadow.light {
  background: #fff;
}

:local .iconShadow.isAnimated {
  visibility: visible;
  animation: .7s ease-in-out folder-open;
}

:local .iconWrapper {
  background: none;
  border: none;
  width: 100%;
  height: 100%;
}

:local .iconWrapper.button {
  background-color: #ffffffe6;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  padding: 9px;
  transition: transform .5s;
  position: relative;
}

:is(:local .iconWrapper.button:active, :local .iconWrapper.button.isActive) {
  transform: scale(.93);
}

:local .iconWrapper.button:after {
  content: "";
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transition: opacity .5s;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 4px #22222226;
}

:local .iconWrapper.button:active:after {
  opacity: 0;
}

:local .iconWrapper.button.isActive:after {
  opacity: 0;
}

:local .folder {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

:local .folder:hover {
  cursor: pointer;
}

:local .folder.button {
  width: 23px;
  height: 23px;
  top: 50%;
  right: 50%;
  transform: translate3d(50%, -50%, 0);
}

:local svg.icon:not(:root) {
  padding: 1px;
  overflow: visible;
}
