.content {
  width: 370px;
  padding: 0 18px;
}

.address,
.associations {
  margin-bottom: 18px;
}

.addressLines {
  margin-bottom: 9px;
}

.byAppointmentOnly {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-style: italic;
  font-weight: 300;
}

.sectionHeader {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
}